import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Link from "next/link";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { colors } from "../utils/const";
import { styles } from "./secondary_navbar";
import { useSelector, useDispatch } from "react-redux";
import Popover from "@mui/material/Popover";
import FlyingCard from "@/components/mini-components/FlyingCard";
import UserDropDown from "./UserDropDown/UserDropDown";
import Button from "@mui/material/Button";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";
import { arFont, enFont } from "@/styles/font";
import { isCompany as isCompanyGetter } from "@/utils/generalUrils";
export default function Secondary_Navbar({
  setIsOPenA,
  setIsOPenB,
  setIsOPenC,
  setIsOPenD,
  setIsOPenE,
  setType,
  arrDirA,
  arrDirB,
  arrDirC,
  arrDirD,
  arrDirE,
  setArrDirA,
  setArrDirB,
  setArrDirC,
  setArrDirD,
  setArrDirE,
  SetLogin,
  type,
  Login,
  LogOut,
}) {
  const [state, setState] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  useEffect(() => {
    setIsCompany(isCompanyGetter());
  }, [isCompanyGetter()]);
  const secTab = useSelector((state) => state.secTab.secTab);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dispatch = useDispatch();
  // const [arrDirA, setArrDirA] = useState("down");
  // const [arrDirB, setArrDirB] = useState("down");
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  useEffect(() => {
    if (localStorage.getItem("UT_UI")) {
      SetLogin(true);
    } else {
      SetLogin(false);
    }
  }, []);

  return (
    <>
      <Popover
        sx={{ mt: 3, zIndex: 100000000000000000000 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          if (type === "b") {
            setArrDirA((prev) => (prev === "up" ? "down" : "up"));
          } else if (type === "c") {
            setArrDirC((prev) => (prev === "up" ? "down" : "up"));
          } else if (type === "d") {
            setArrDirB((prev) => (prev === "up" ? "down" : "up"));
          } else if (type === "x") {
            setArrDirD((prev) => (prev === "up" ? "down" : "up"));
          } else if (type === "y") {
            setArrDirE((prev) => (prev === "up" ? "down" : "up"));
          }
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: locale === "en" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: locale === "en" ? "left" : "right",
        }}
      >
        <FlyingCard
          setState={setState}
          setAnchorEl={setAnchorEl}
          setArrDirC={setArrDirC}
          setArrDirA={setArrDirA}
          setArrDirB={setArrDirB}
          setArrDirD={setArrDirD}
          setArrDirE={setArrDirE}
          type={type}
        />
      </Popover>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Box sx={styles.container} className="horizontal-safe-padding">
          <Link href={isCompany ? "/companies" : "/"}>
            <Box
              component="img"
              src={t.logo}
              alt="Picture of the author"
              width="77px"
              height="22px"
              loading="lazy"
            />
          </Link>

          <Box
            onClick={(event) => {
              setArrDirA((prev) => (prev == "up" ? "down" : "up"));
              setIsOPenA((prev) => !prev);
              setArrDirB("down");
              setIsOPenB(false);
              setArrDirC("down");
              setIsOPenC(false);
              setType("b");
              setAnchorEl(event.currentTarget);
            }}
            sx={{
              ...styles.tab,
              "&:hover": { cursor: "pointer" },
              "& svg": {
                transition: "1s",
                transform: `rotate(${arrDirA === "up" ? "-180deg" : "0deg"})`,
              },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: colors.navy_blue,
              textDecoration: "none",
              transition: "1s",
            }}
          >
            {t.secondary_nav.financial_services}
            <KeyboardArrowDownIcon />
          </Box>
          <Box
            onClick={(event) => {
              setArrDirC((prev) => (prev == "up" ? "down" : "up"));
              setIsOPenC((prev) => !prev);
              setArrDirA("down");
              setIsOPenA(false);
              setArrDirB("down");
              setIsOPenB(false);
              setType("c");
              setAnchorEl(event.currentTarget);
            }}
            sx={{
              ...styles.tab,

              "&:hover": { cursor: "pointer" },
              "& svg": {
                transition: "1s",
                transform: `rotate(${arrDirC === "up" ? "-180deg" : "0deg"})`,
              },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: colors.navy_blue,
              textDecoration: "none",
              transition: "1s",
            }}
          >
            {t.secondary_nav.insurance_services}
            <KeyboardArrowDownIcon />
          </Box>

          {!isCompany && (
            <Box
              component={Link}
              href="/products/contact-now"
              onClick={() => {}}
              sx={styles.tab}
            >
              {t.secondary_nav.contact_now}
            </Box>
          )}

          {!isCompany && (
            <Box
              component={Link}
              href="/investment"
              onClick={() => {}}
              sx={styles.tab}
            >
              {t.secondary_nav.investment_and_savings}
            </Box>
          )}

          {!isCompany && (
            <Box
              component={Link}
              href="/e-payment"
              onClick={() => {}}
              sx={styles.tab}
            >
              {t.secondary_nav.e_payments}
            </Box>
          )}

          {!isCompany && (
            <Box
              onClick={(event) => {
                setArrDirB((prev) => (prev == "up" ? "down" : "up"));
                setIsOPenB((prev) => !prev);
                setArrDirA("down");
                setIsOPenA(false);
                setType("d");
                setAnchorEl(event.currentTarget);
              }}
              sx={{
                ...styles.tab,

                "&:hover": { cursor: "pointer" },
                "& svg": {
                  transition: "1s",
                  transform: `rotate(${arrDirB === "up" ? "-180deg" : "0deg"})`,
                },
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: colors.navy_blue,
                textDecoration: "none",
                transition: "1s",
              }}
            >
              {t.secondary_nav.clients_program}
              <KeyboardArrowDownIcon />
            </Box>
          )}

          {!isCompany && (
            <Box
              component={Link}
              href="/our-branches"
              onClick={() => {}}
              sx={styles.tab}
            >
              {t.secondary_nav.branches_location}
            </Box>
          )}

          {isCompany && (
            <Box
              component={Link}
              href="/buisness-investment"
              onClick={() => {}}
              sx={styles.tab}
            >
              {t.navbar_investment}
            </Box>
          )}

          {isCompany && (
            <Button
              variant="contained"
              sx={styles.downloadBtn}
              style={{
                [locale == "en" ? "marginLeft" : "marginRight"]:
                  isCompany && "auto",
              }}
              aria-label="become a partner "
              onClick={() => {
                window.location.pathname !==
                  "/partnership/contact-now-merchant" &&
                window.location.pathname !== "/partnership/medical-supplies" &&
                window.location.pathname !==
                  "/partnership/vehicles-and-transportation-vendor" &&
                window.location.pathname !== "/partnership/other-entities"
                  ? router.push("/partnership/contact-now-merchant")
                  : null;
              }}
            >
              {t.navbar_partnership}
            </Button>
          )}
          {!isCompany && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mr: { xs: 0, md: locale === "en" ? 0 : "auto" },
                  ml: { xs: 0, md: locale === "en" ? "auto" : 0 },
                }}
              >
                {!Login ? (
                  <Button
                    variant="outlined"
                    aria-label="Login button"
                    component={Link}
                    sx={styles.loginBtn}
                    href="/login"
                  >
                    {t.secondary_nav.login}
                  </Button>
                ) : (
                  <UserDropDown
                    SetLogin={SetLogin}
                    LogOut={LogOut}
                  ></UserDropDown>
                )}
              </Box>
              <Button
                variant="contained"
                sx={styles.downloadBtn}
                style={{
                  [locale == "en" ? "marginLeft" : "marginRight"]:
                    isCompany && "auto",
                }}
                aria-label="Download app button "
                component="a"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=eg.contact"
              >
                {t.secondary_nav.download_app}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
