import React, { useLayoutEffect, useState, useEffect } from "react";
import { Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Link from "next/link";
import Button from "@mui/material/Button";
import { colors } from "../utils/const";
import { styles } from "./main_navbar.js";
import { mainTabActions, localAction } from "../Redux/store";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";
import { useSelector, useDispatch } from "react-redux";
import { isCompany } from "@/utils/generalUrils";
import { Block } from "@mui/icons-material";

export default function Main_Navbar({ anchorEl, setAnchorEl }) {
  const mainTab = useSelector((state) => state.mainTab.mainTab);

  const router = useRouter();

  const dispatch = useDispatch();
  const { locale } = router;
  const t = locale === "en" ? en : ar;

  useEffect(() => {
    const storedLocale = sessionStorage.getItem("lang") || locale;
    if (storedLocale && storedLocale !== locale) {
      replaceLanguage(storedLocale);
    }
  }, [locale]);

  const replaceLanguage = (newLocale) => {
    sessionStorage.setItem("lang", newLocale);
    const { pathname, query } = router;
    router.replace({ pathname: `/${newLocale}${pathname}`, query }, undefined, {
      locale: newLocale,
    });
  };

  const toAr = () => replaceLanguage("ar");
  const toEn = () => replaceLanguage("en");

  useLayoutEffect(() => {
    if (isCompany()) {
      dispatch(mainTabActions.update("شركات"));
    } else {
      dispatch(mainTabActions.update("افراد"));
    }
  }, [isCompany()]);
  return (
    <Box sx={styles.container} className="horizontal-safe-padding">
      <Button
        aria-label="Menu drop down button"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        // disableRipple
        sx={{
          minWidth: "50px",
          py: 1,
          justifyContent: "start",
          px: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:active": {
            backgroundColor: "inherit", // Change to your desired background color
            color: "inherit", // Change to your desired text color
            transition: "none", // Remove the transition
          },
        }}
      >
        {anchorEl ? (
          <CloseIcon
            sx={{
              fontSize: 30,
              color: colors.blue,
            }}
          />
        ) : (
          <MenuIcon
            sx={{
              fontSize: 30,
              color: colors.blue,
            }}
          />
        )}
      </Button>
      <Box
        component={Link}
        sx={{
          ...styles.tab,
          fontWeight: mainTab === "افراد" ? "bold" : "normal",
          borderBottom:
            mainTab === "افراد" ? `2px solid ${colors.blue}` : "none",
        }}
        href="/"
        onClick={() => {
          dispatch(mainTabActions.update("افراد"));
        }}
      >
        {t.main_nav.individuals}
      </Box>
      <Box
        component={Link}
        sx={{
          ...styles.tab,
          fontWeight: mainTab === "شركات" ? "bold" : "normal",
          borderBottom:
            mainTab === "شركات" ? `2px solid ${colors.blue}` : "none",
        }}
        href="/companies"
        onClick={() => {
          dispatch(mainTabActions.update("شركات"));
        }}
      >
        {t.main_nav.companies}
      </Box>
      <Box
        onClick={locale === "en" ? toAr : toEn}
        component={Button}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
          color: colors.blue,
          fontWeight: "bold",
          mr: locale === "en" ? 0 : "auto",
          ml: locale === "en" ? "auto" : 0,
          justifyContent: "end",
        }}
      >
        {t.main_nav.lang}
      </Box>
    </Box>
  );
}
