import Main_Navbar from "../components/Main_Navbar.jsx";
import Secondary_Navbar from "../components/Secondary_Navbar.jsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Drawer } from "@mui/material";
import { colors } from "../utils/const";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import SideDrawer from "../components/SideDrawer.jsx";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";
import { useSelector, useDispatch } from "react-redux";
import Link from "next/link";
import { useEffect, useState } from "react";
import Image from "next/legacy/image";
import { localAction } from "../Redux/store";

export default function Navbar({
  leftOrRightValue,
  setLeftOrRightValue,
  setIsOPenA,
  setIsOPenB,
  setIsOPenC,
  setIsOPenD,
  setIsOPenE,
  state,
  setState,
  setType,
  arrDirA,
  arrDirB,
  arrDirC,
  arrDirD,
  arrDirE,
  setArrDirA,
  setArrDirB,
  setArrDirC,
  setArrDirD,
  setArrDirE,
  anchorEl,
  setAnchorEl,
  SetLogin,
  Login,
  type,
  LogOut,
}) {
  const [show, setShow] = useState(false);

  const mainTab = useSelector((state) => state.mainTab.mainTab);
  const gif = useSelector((state) => state.gif.gif);

  const matches = useMediaQuery("(max-width:900px)");
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;

  useEffect(() => {
    const storedLocale = sessionStorage.getItem("lang") || locale;
    if (storedLocale && storedLocale !== locale) {
      replaceLanguage(storedLocale);
    }
  }, [locale]);

  const replaceLanguage = (newLocale) => {
    sessionStorage.setItem("lang", newLocale);
    const { pathname, query } = router;
    router.replace({ pathname: `/${newLocale}${pathname}`, query }, undefined, {
      locale: newLocale,
    });
  };

  const toAr = () => replaceLanguage("ar");
  const toEn = () => replaceLanguage("en");

  const [user, setUser] = useState(true);

  return (
    <>
      <Box>
        <Box
          sx={{
            // zIndex: "100000000000",
            zIndex: "999",
            position: "fixed",
            bottom: { xs: "80px", md: "100px" },
            height: { xs: "80px", md: "120px" },
            width: { xs: "80px", md: "120px" },
            [locale === "ar" ? "right" : "left"]: {
              xs: "6px",
              md: "0px",
            },
          }}
        >
          <Image
            loading="lazy"
            src={gif == "hi" ? t.general.gifs.hi : t.general.gifs.calculationg}
            alt="my gif"
            // sizes="100vw"
            layout="fill"
          />
        </Box>

        <Box
          component="img"
          src={t.general.popup}
          alt="whatsapp text"
          loading="lazy"
          sx={{
            // zIndex: "100000",
            zIndex: "999",
            height: { xs: "60px", md: "100px", lg: "120px" },
            position: "fixed",
            bottom: { xs: "20px", md: "15px", lg: "5px" },
            transition: "1s",
            opacity: show ? "1" : "0",
            // visibility: show ? "1" : "0",
            display: show ? "block" : "none",
            [locale === "ar" ? "right" : "left"]: { xs: "70px", md: "90px" },
          }}
        />
        <Box
          component="a"
          href="https://wa.me/20216177"
          target="_blank"
          sx={{
            zIndex: "999",
            height: { xs: "40px", md: "55px" },
            width: { xs: "40px", md: "55px" },
            position: "fixed",
            bottom: "32px",
            [locale === "ar" ? "right" : "left"]: { xs: "25px", lg: "35px" },
          }}
          onMouseLeave={() => {
            setShow(false);
          }}
          onMouseEnter={() => {
            setShow(true);
          }}
        >
          <Box
            component="img"
            src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/whatsapp.png"
            alt="whatsapp chat"
            loading="lazy"
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Box>

      {user && (
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: "111",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
          }}
        >
          {/* large screens  */}
          {!matches && (
            <>
              <Main_Navbar
                setLeftOrRightValue={setLeftOrRightValue}
                leftOrRightValue={leftOrRightValue}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                type={type}
                setType={setType}
              ></Main_Navbar>
              <Secondary_Navbar
                setIsOPenA={setIsOPenA}
                setIsOPenB={setIsOPenB}
                setIsOPenC={setIsOPenC}
                setIsOPenD={setIsOPenD}
                setIsOPenE={setIsOPenE}
                type={type}
                setType={setType}
                arrDirA={arrDirA}
                arrDirB={arrDirB}
                arrDirC={arrDirC}
                arrDirD={arrDirD}
                arrDirE={arrDirE}
                setArrDirA={setArrDirA}
                setArrDirB={setArrDirB}
                setArrDirC={setArrDirC}
                setArrDirD={setArrDirD}
                setArrDirE={setArrDirE}
                user
                Login={Login}
                SetLogin={SetLogin}
                LogOut={LogOut}
              ></Secondary_Navbar>
            </>
          )}

          {/* small screens */}
          {matches && (
            <>
              <Box
                className="horizontal-safe-padding"
                sx={{
                  py: 1,
                  bgcolor: colors.dark_grey,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="Toggle menu"
                  sx={{
                    color: colors.blue,
                    justifyContent: "start",
                    px: 0,
                    "&:hover, &:active": {
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={() => {
                    setState((prev) => !prev);
                  }}
                >
                  <MenuIcon sx={{}} />
                </IconButton>
                <Box component={Link} href="/">
                  <Box
                    component="img"
                    alt="image"
                    src={t.logo}
                    sx={
                      {
                        // width: { xs: "56px", sm: "60px" },
                        // height: { xs: "19px", sm: "22px" },
                      }
                    }
                    height="22px"
                    width="80px"
                    loading="lazy"
                  />
                </Box>
                <Box
                  onClick={locale === "en" ? toAr : toEn}
                  component={Button}
                  sx={{
                    color: colors.blue,
                    fontWeight: "bold",
                    justifyContent: "end",
                    px: 0,
                    minWidth: 0,
                  }}
                >
                  {t.main_nav.lang}
                </Box>
              </Box>
              <SideDrawer
                state={state}
                setState={setState}
                Login={Login}
                SetLogin={SetLogin}
                LogOut={LogOut}
              />
            </>
          )}
        </Box>
      )}
    </>
  );
}
