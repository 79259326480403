import React, { useEffect, useLayoutEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Service from "./Service.jsx";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";
import Link from "next/link";
import { styles } from "./secondary_navbar";
import { arFont, enFont } from "@/styles/font";
import { mainTabActions, gifActions } from "../Redux/store";
import { useSelector, useDispatch } from "react-redux";
import { colors } from "../utils/const";
import { isCompany } from "@/utils/generalUrils";

export default function SideDrawer({
  state,
  setState,
  Login,
  SetLogin,
  LogOut,
}) {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const mainTab = useSelector((state) => state.mainTab.mainTab);
  const isCompanyCheck = mainTab === "شركات";
  const dispatch = useDispatch();

  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  const toggleDrawer = () => () => {
    setState((prev) => !prev);
  };
  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  useEffect(() => {
    if (localStorage.getItem("UT_UI")) {
      SetLogin(true);
    } else {
      SetLogin(false);
    }
  }, []);
  useLayoutEffect(() => {
    if (isCompany()) {
      dispatch(mainTabActions.update("شركات"));
    } else {
      dispatch(mainTabActions.update("افراد"));
    }
  }, []);
  return (
    <div>
      <Drawer
        anchor={locale === "en" ? "left" : "right"}
        open={state}
        onClose={toggleDrawer()}
        dir={locale === "en" ? "ltr" : "rtl"}
        sx={{ zIndex: "9999999 !important" }}
      >
        <Box
          sx={{
            width: { xs: 300, sm: 300 },
            "& span": { textAlign: locale === "en" ? "left" : "right" },
          }}
          role="presentation"
        >
          <Box
            component={Link}
            sx={styles.tab}
            style={{
              fontWeight: mainTab === "افراد" ? "bold" : "normal",
              borderBottom:
                mainTab === "افراد"
                  ? `1px solid ${colors.blue}`
                  : `1px dashed ${colors.blue}`,
              padding: "10px",
            }}
            href="/"
            onClick={() => {
              dispatch(mainTabActions.update("افراد"));
            }}
            className={locale === "en" ? enFont.className : arFont.className}
          >
            {t.main_nav.individuals}
          </Box>
          <Box
            component={Link}
            sx={styles.tab}
            style={{
              fontWeight: mainTab === "شركات" ? "bold" : "normal",
              borderBottom:
                mainTab === "شركات"
                  ? `1px solid ${colors.blue}`
                  : `1px dashed ${colors.blue}`,
              padding: "10px",
            }}
            href="/companies"
            onClick={() => {
              dispatch(mainTabActions.update("شركات"));
            }}
            className={locale === "en" ? enFont.className : arFont.className}
          >
            {t.main_nav.companies}
          </Box>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleClick1}>
                <Box
                  component="img"
                  src="/sideDrawer/financial.svg"
                  alt="financial"
                  sx={{
                    ml: locale === "ar" ? "8px" : "0px",
                    mr: locale === "en" ? "8px" : "0px",
                  }}
                />
                <ListItemText
                  className={
                    locale == "en" ? enFont.className : arFont.className
                  }
                  primary={t.secondary_nav.financial_services}
                />
                {open1 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <Box
                sx={{ display: "flex", flexWrap: "wrap", gap: 1, m: "6px" }}
                className={locale == "en" ? enFont.className : arFont.className}
              >
                {t.home_page.services.All.filter((item) => {
                  if (isCompany()) {
                    return item.id == "10" || item.id == "11";
                  } else {
                    return item.id !== "10" && item.id !== "11";
                  }
                }).map(({ color, title, description, img, url }, index) => (
                  <Service
                    url={url}
                    key={index}
                    color={color}
                    title={title}
                    description={description}
                    img={img}
                    setState={setState}
                  />
                ))}
              </Box>
            </Collapse>
            <ListItem disablePadding>
              <ListItemButton onClick={handleClick2}>
                <Box
                  component="img"
                  src="/sideDrawer/insurance.svg"
                  alt="insurance"
                  sx={{
                    ml: locale === "ar" ? "8px" : "0px",
                    mr: locale === "en" ? "8px" : "0px",
                  }}
                />

                <ListItemText
                  primary={t.secondary_nav.insurance_services}
                  className={
                    locale == "en" ? enFont.className : arFont.className
                  }
                />

                {open2 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <Box
                sx={{ display: "flex", flexWrap: "wrap", gap: 1, m: "7px" }}
                className={locale == "en" ? enFont.className : arFont.className}
              >
                {t.secondary_nav.insurance_services_dropdown.map(
                  ({ color, title, img, url }, index) => (
                    <Service
                      url={url}
                      key={index}
                      color={color}
                      title={title}
                      img={img}
                      setState={setState}
                    />
                  )
                )}
                <Box
                  component={Link}
                  href="/products/insurance"
                  sx={{
                    textDecoration: "none",
                    m: "auto",
                    color: "#233266",
                  }}
                  onClick={() => {
                    setState((prev) => !prev);
                  }}
                >
                  {t.secondary_nav.insurance_services_dropdown.brokerge}
                </Box>
              </Box>
            </Collapse>
            {!isCompanyCheck && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    router.push("/contact-uae");
                    setState((prev) => !prev);
                  }}
                >
                  <Box
                    component="img"
                    src="/uae-flag.svg"
                    alt="contactnow"
                    sx={{
                      ml: locale === "ar" ? "8px" : "0px",
                      mr: locale === "en" ? "8px" : "0px",
                      width: "20px",
                    }}
                  />

                  <ListItemText
                    primary={t.secondary_nav.contact_uae}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}

            {!isCompanyCheck && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    router.push("/products/contact-now");
                    setState((prev) => !prev);
                  }}
                >
                  <Box
                    component="img"
                    src="/our-brands/c-contact-now.png"
                    alt="contactnow"
                    sx={{
                      ml: locale === "ar" ? "8px" : "0px",
                      mr: locale === "en" ? "8px" : "0px",
                      width: "20px",
                    }}
                  />

                  <ListItemText
                    primary={t.secondary_nav.contact_now}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
            {/* {!isCompanyCheck && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    router.push("/investment");
                    setState((prev) => !prev);
                  }}
                >
                  <Box
                    component="img"
                    src="/sideDrawer/investement.svg"
                    alt="investment"
                    sx={{
                      ml: locale === "ar" ? "8px" : "0px",
                      mr: locale === "en" ? "8px" : "0px",
                    }}
                  />
                  <ListItemText
                    primary={t.secondary_nav.investment_and_savings}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  />
                </ListItemButton>
              </ListItem>
            )} */}
            {!isCompanyCheck && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    router.push("/e-payment");
                    setState((prev) => !prev);
                  }}
                >
                  <Box
                    component="img"
                    src="/sideDrawer/payment.svg"
                    alt="payment"
                    sx={{
                      ml: locale === "ar" ? "8px" : "0px",
                      mr: locale === "en" ? "8px" : "0px",
                    }}
                  />

                  <ListItemText
                    primary={t.secondary_nav.e_payments}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
             {!isCompanyCheck && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    router.push("/why-contact");
                    setState((prev) => !prev);
                  }}
                >
                  <Box
                    component="img"
                    src="/sideDrawer/contct programs.svg"
                    alt="payment"
                    sx={{
                      ml: locale === "ar" ? "8px" : "0px",
                      mr: locale === "en" ? "8px" : "0px",
                    }}
                  />

                  <ListItemText
                    primary={t.secondary_nav.why_contact}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
            {!isCompanyCheck && (
              <>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleClick3}>
                    <Box
                      component="img"
                      src="/sideDrawer/contct programs.svg"
                      alt="contact program"
                      sx={{
                        ml: locale === "ar" ? "8px" : "0px",
                        mr: locale === "en" ? "8px" : "0px",
                      }}
                    />

                    <ListItemText
                      primary={t.secondary_nav.clients_program}
                      className={
                        locale == "en" ? enFont.className : arFont.className
                      }
                    />
                    {open3 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={open3} timeout="auto" unmountOnExit>
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 1, m: "7px" }}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  >
                    {t.home_page.services.C.map(
                      ({ color, title, description, img, url }, index) => (
                        <Service
                          url={url}
                          key={index}
                          color={color}
                          title={title}
                          description={description}
                          img={img}
                          setState={setState}
                        />
                      )
                    )}
                  </Box>
                </Collapse>
              </>
            )}
            {!isCompanyCheck && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    router.push("/our-branches");
                    setState((prev) => !prev);
                  }}
                >
                  <Box
                    component="img"
                    src="/sideDrawer/map.svg"
                    alt="map"
                    sx={{
                      ml: locale === "ar" ? "8px" : "0px",
                      mr: locale === "en" ? "8px" : "0px",
                    }}
                  />

                  <ListItemText
                    primary={t.secondary_nav.branches_location}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
            {isCompanyCheck && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    router.push("/buisness-investment");
                    setState((prev) => !prev);
                  }}
                >
                  <Box
                    component="img"
                    src="/sideDrawer/investement.svg"
                    alt="investment"
                    sx={{
                      ml: locale === "ar" ? "8px" : "0px",
                      mr: locale === "en" ? "8px" : "0px",
                    }}
                  />
                  <ListItemText
                    primary={t.navbar_investment}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
            {isCompanyCheck && (
              <>
                <ListItem disablePadding>
                  <ListItemButton onClick={handleClick4}>
                    <Box
                      component="img"
                      alt="contact program"
                      src="/sideDrawer/contct programs.svg"
                      sx={{
                        ml: locale === "ar" ? "8px" : "0px",
                        mr: locale === "en" ? "8px" : "0px",
                      }}
                    />

                    <ListItemText
                      primary={t.navbar_partnership}
                      className={
                        locale == "en" ? enFont.className : arFont.className
                      }
                    />
                    {open4 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={open4} timeout="auto" unmountOnExit>
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 1, m: "7px" }}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  >
                    {t.partner_dropdown.map(
                      ({ color, title, description, img, url }, index) => (
                        <Service
                          url={url}
                          key={index}
                          color={color}
                          title={title}
                          description={description}
                          img={img}
                          setState={setState}
                        />
                      )
                    )}
                  </Box>
                </Collapse>
              </>
            )}

            <Box>
              {!Login && !isCompanyCheck && (
                <Box
                  disablePadding
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="outlined"
                    araia-aria-label="Login button in mobile screen"
                    sx={{ ...styles.loginBtn, mx: 2, mt: 2, width: "100%" }}
                    onClick={() => {
                      router.push("/login");
                      setState((prev) => !prev);
                    }}
                    className={
                      locale == "en" ? enFont.className : arFont.className
                    }
                  >
                    {t.secondary_nav.login}
                  </Button>
                </Box>
              )}
              {Login && !isCompanyCheck && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        router.push("/profile");
                        setState((prev) => !prev);
                      }}
                    >
                      <Box
                        component="img"
                        alt="profile"
                        src="/sideDrawer/profile.svg"
                        sx={{
                          ml: locale === "ar" ? "8px" : "0px",
                          mr: locale === "en" ? "8px" : "0px",
                        }}
                      />
                      <ListItemText
                        primary={t.userNav.profile}
                        className={
                          locale == "en" ? enFont.className : arFont.className
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        router.push("/contract");
                        setState((prev) => !prev);
                      }}
                    >
                      <Box
                        component="img"
                        alt="installment"
                        src="/sideDrawer/installement.svg"
                        sx={{
                          ml: locale === "ar" ? "8px" : "0px",
                          mr: locale === "en" ? "8px" : "0px",
                        }}
                      />
                      <ListItemText
                        primary={t.userNav.your_installement}
                        className={
                          locale == "en" ? enFont.className : arFont.className
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        router.push("/customerservices");
                        setState((prev) => !prev);
                      }}
                    >
                      <Box
                        component="img"
                        alt="call"
                        src="/sideDrawer/call.svg"
                        sx={{
                          ml: locale === "ar" ? "8px" : "0px",
                          mr: locale === "en" ? "8px" : "0px",
                        }}
                      />
                      <ListItemText
                        primary={t.userNav.call_center}
                        className={
                          locale == "en" ? enFont.className : arFont.className
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="outlined"
                      aria-label="Logout button in mobile screen"
                      sx={{ ...styles.loginBtn, mx: 2, mt: 2, width: "100%" }}
                      onClick={() => {
                        LogOut();
                        localStorage.removeItem("bro-to");
                        setState((prev) => !prev);
                      }}
                      className={
                        locale == "en" ? enFont.className : arFont.className
                      }
                    >
                      {t.userNav.logout}
                    </Button>
                  </ListItem>
                </>
              )}
            </Box>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}
